import { createSlice } from "@reduxjs/toolkit";
import storage from "../../services/storage";

const userSearchesList = storage.get("userSearchesList") || [];
const UserSearch = createSlice({
  name: "userGamesSearch",
  initialState: {
    userSearchesList,
    searchText: "",
    searched: true,
  },
  reducers: {
    setSearchText: (state, action) => {
      state.searchText = action.payload;
    },

    addSearch(state, action) {
      const { clear, value } = action.payload;
      if (clear) state.userSearchesList = [];
      else
        state.userSearchesList = state.userSearchesList.filter(
          (item) => item !== value
        );
      state.userSearchesList.unshift(value);
      storage.store("userSearchesList", JSON.stringify(state.userSearchesList));
    },

    removeSearch(state, action) {
      const searchTerm = action.payload;
      state.userSearchesList = state.userSearchesList.filter(
        (item) => item !== searchTerm
      );
      storage.store("userSearchesList", JSON.stringify(state.userSearchesList));
    },

    getUserSearches: (state, action) => {
      const searchTerm = action.payload;

      state.searched = !state.searched;

      if (searchTerm) {
        state.userSearchesList = state.userSearchesList.filter(
          (item) => item !== searchTerm
        );
        state.userSearchesList.unshift(searchTerm);
        const userSearchListed = JSON.stringify(state.userSearchesList);
        storage.store("userSearchesList", userSearchListed);
      }
    },
    emptyRecentSerach: (state, action) => {
      state.userSearchesList = [];
    },
    deleteRresentSearch: (state, action) => {
      const searchText = action.payload;
      const findRecentItem = state.userSearchesList.filter(
        (item) => item !== searchText
      );
      state.userSearchesList = findRecentItem;
      const userSearchListed = JSON.stringify(state.userSearchesList);
      storage.store("userSearchesList", userSearchListed);
    },
  },
});
export const usserSearchActions = UserSearch.actions;
export const {
  getUserSearches,
  setSearchText,
  deleteRresentSearch,
  emptyRecentSerach,
} = UserSearch.actions;
export default UserSearch.reducer;

import { createSlice } from "@reduxjs/toolkit";

const userBillingHistory = createSlice({
  name: "billingHistory",
  initialState: {
    billHistory: [],
    getAllUserBillHis: [],
    loading: false,
    perPage: 1,
    hasMore: false,
    perPageLimit: 4,
  },
  reducers: {
    setUserBillHistory: (state, action) => {
      const { count, totalCount, getbillingHistory } = action.payload;
      const billHistory = [...state.billHistory, ...getbillingHistory];
      state.billHistory = billHistory;
      state.loading = false;
      state.hasMore = billHistory.length < totalCount;
    },
    setLoading: (state, action) => {
      const loading = action.payload;
      state.loading = loading;
      if (loading) state.perPage += 1;
    },
    setAllUserBill: (state, action) => {
      state.getAllUserBillHis = action.payload;
    },
  },
});
export const { setUserBillHistory, setLoading, incrementPage, setAllUserBill } =
  userBillingHistory.actions;
export default userBillingHistory.reducer;

function store(key, value) {
  if (typeof window === "undefined") return;
  if (key === "user") {
    value = JSON.stringify(value);
  }
  localStorage.setItem(key, value);
}

function get(key) {
  if (typeof window === "undefined") return;

  let data = localStorage.getItem(key);
  if (!data) return "";
  try {
    data = JSON.parse(data);
  } catch (e) {
    data = data;
  }
  return data;
}

function remove(key) {
  if (typeof window === "undefined") return;

  localStorage.removeItem(key);
}

const storage = { store, get, remove };
export default storage;

import { createSlice } from "@reduxjs/toolkit";

const providerData = createSlice({
  name: "providerData",
  initialState: {
    providerValues: ['evoplay', 'netgame', 'arrowedge'], // Start with no providers selected
  },
  reducers: {
    toggleProvider: (state, action) => {
      const value = action.payload;
      const { providerValues } = state;

      if (providerValues.includes(value)) {
        // Deselect the provider if it's already selected
        state.providerValues = providerValues.filter(provider => provider !== value);
      } else {
        // Select the provider if it's not already selected
        state.providerValues = [...providerValues, value];
      }
    },
    selectAllProviders: (state, action) => {
      state.providerValues = action.payload; // Select all providers
    },
  },
});

export const { toggleProvider, selectAllProviders } = providerData.actions;
export default providerData.reducer;

import { createSlice } from "@reduxjs/toolkit";

const userCashoutHistory = createSlice({
  name: "cashoutHistory",
  initialState: {
    cashoutHistory: [],
    getAllUserBillHis: [],
    loading: false,
    perPage: 1,
    hasMore: false,
    perPageLimit: 4,
  },
  reducers: {
    setUserCashoutHistory: (state, action) => {
      // console.log('action.payload of billing history', action.payload)
      const { count, totalCount, cashoutHistory,  } = action.payload;
      const cashouthis = [...state.cashoutHistory, ...cashoutHistory];
      state.cashoutHistory = cashouthis;
      state.loading = false;
      state.hasMore = cashouthis.length < totalCount;
    },
    setLoading: (state, action) => {
      const loading = action.payload;
      state.loading = loading;
      if (loading) state.perPage += 1;
    },
    setAllUserBill: (state, action) => {
      state.getAllUserBillHis = action.payload;
    },
  },
});
export const { setUserCashoutHistory, setLoading, incrementPage, setAllUserBill } =
  userCashoutHistory.actions;
export default userCashoutHistory.reducer;

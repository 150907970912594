/* eslint-disable max-len */
import { createSlice } from "@reduxjs/toolkit";

const notificationsSlice = createSlice({
  name: "notifications",
  initialState: {
    all: [],
    unread: [],
    paginationData: [],
    paginationTotalCount: 0,
    activeNotification: {},
    loading: false,
    perPage: 1,
    hasMore: false,
    perPageLimit: 10,
    status: "idle",
  },

  reducers: {
    addNotifications: (state, action) => {
      const exists = state.unread.some((notification) => notification._id === action.payload._id);
      if (!exists) {
        state.all.unshift(action.payload);
        state.unread.unshift(action.payload);
      }
      // console.log("payload of add notification", action.payload);
    },
    getAllNotifications: (state, action) => {
      const { notifications } = action.payload;
      const unreadNotifications = notifications.filter(
        (notification) => notification.status === "unread"
      );
      // console.log("unread notifications", unreadNotifications);
      return {
        ...state,
        unread: unreadNotifications,
        loading: false,
        all: notifications,
        // hasMore: notifications.length < totalCount,
      };
    },
    getAllNotificationPagination: (state, action) => {
      const { notifications, totalCount } = action.payload;
      const newState = {
        ...state,
        paginationData: state.perPage === 1 ? notifications : [...state.paginationData, ...notifications],
        loading: false,
        hasMore: state.paginationData.length < totalCount,
        paginationTotalCount: totalCount
      };
      return newState;
    },
    // getAllNotifications: (state, action) => {
    //   const { notifications } = action.payload;

    //   const unreadNotifications = notifications.filter(
    //     (notification) => notification.status === "unread"
    //   );
    //   // console.log("unread notifications", unreadNotifications);
    //   state.unread = unreadNotifications;
    //   state.loading = false;
    //   state.all = notifications;
    //   // state.hasMore = notifications.length < totalCount;
    // },
    // getAllNotificationPagination: (state, action) => {
    //   // console.log('action.payload of billing history', action.payload)
    //   const { notifications, totalCount } = action.payload;
    //   // console.log("payload of paging history", action.payload);
    //   if (state.perPage === 1) {
    //     state.paginationData = notifications; // Replace with new notifications if it's the first page
    //   } else {
    //     state.paginationData = [...state.paginationData, ...notifications]; // Concatenate otherwise
    //   }

    //   // const notificationHistory = [...state.paginationData, ...notifications];
    //   // state.paginationData = notificationHistory;
    //   state.loading = false;
    //   state.hasMore = state.paginationData.length < totalCount;
    // },
    // getSingleNotification: (state, action) => {
    //   state.activeNotification = action.payload;
    // },
    getSingleNotification: (state, action) => {
      return {
        ...state,
        activeNotification: action.payload,
        loading: false
      };
    },
    // removeNotification: (state, action) => {
    //   const newState = { ...state }; // Create a copy of the state object
    //   const id = action.payload;
    //   console.log("Remove notification of id", newState);
    //   const findIndex = newState.unread.findIndex((item) => item._id === id);
    //   console.log('new state index', newState.unread);
    //   if (findIndex > -1) {
    //     newState.unread.splice(findIndex, 1);
    //   }
    //   console.log("Remove notification find index", findIndex);

    //   return newState; // Return the modified copy of the state
    // },
    removeNotification: (state, action) => {
      // console.log('remove notification called', action.payload);
      const id = action.payload;
      const findIndex = state.unread.findIndex((item) => item._id === id);
      // console.log('remove notification called', findIndex);
      if (findIndex > -1) {
        state.unread.splice(findIndex, 1);
      }
    },
    // setPageLoad: (state, action) => {
    //   state.loading = action.payload;
    // },
    // setLoading: (state, action) => {
    //   const loading = action.payload;
    //   state.loading = loading;
    //   if (loading) state.perPage += 1;
    // },
    setPageLoad: (state, action) => {
      return {
        ...state,
        loading: action.payload,
      };
    },
    setLoading: (state, action) => {
      const loading = action.payload;
      return {
        ...state,
        loading,
        perPage: loading ? state.perPage + 1 : state.perPage,
      };
    },
    setAllRead: (state) => {
      return {
        ...state,
        unread: [],
      };
    },
  },
});
export const {
  addNotifications,
  removeNotification,
  getAllNotifications,
  getSingleNotification,
  setLoading,
  getAllNotificationPagination,
  setPageLoad,
  setAllRead,
} = notificationsSlice.actions;
export default notificationsSlice.reducer;

import React from "react";
import logo from "../../assets/images/lions-den-tm.png";

export default function Loader({gamePage}) {

  return (
    <div>
      <div className={`${!gamePage ? "loader-main-wrapper" : "loader-main-wrapper-game-page"} `}>
        <img src={logo} alt="loader-img" />
      </div>
    </div>
  );
}

import "./index.css";
import "./App.css";
import "./assets/style.css";
import "./assets/style2.css";
import "./assets/responsive.css";
import "bootstrap/dist/css/bootstrap.css";

import "./assets/HomeAssets/assets/css/style.css";
import "./assets/HomeAssets/assets/css/responsive.css";
import "react-datepicker/dist/react-datepicker.css";

import React from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import { ToastContainer } from "react-toastify";
import store from "./store/Store";
import App from "./App";
import { Provider } from "react-redux";

const env = process.env.REACT_APP_ENV;
const root = ReactDOM.createRoot(document.getElementById("root"));

const app = (
  <>
    <Provider store={store}>
      <App />
      <ToastContainer position="top-right" theme="dark" />
    </Provider>
  </>
);

root.render(env === "production" ? <>{app}</> : <React.StrictMode>{app}</React.StrictMode>);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
